@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amiri&family=Open+Sans&family=Poiret+One&family=Reem+Kufi&family=Roboto&display=swap);
.input-field > label {
  right: 0 !important;
  left: auto !important;
}

.dropdown-content li {
  text-align: right !important;
  margin-right: 50px;
}

.select-wrapper input.select-dropdown {
  direction: rtl;
  margin-right: 100px;
}

:root {
  --primary-color: #3a4052;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  direction: rtl;
  line-height: 1.5;
  font-family: "Amiri", serif;
}

a {
  text-decoration: none;
  color: #3a4052;
  color: var(--primary-color);
}

.showcase {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #fff;
}

.video-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #3a4052 url("/img/1.jpg") no-repeat center
    center/cover;
  background: var(--primary-color) url("/img/1.jpg") no-repeat center
    center/cover;
}

.content {
  z-index: 10;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 128, 128, 0.8);
}

ol {
  padding-right: 50px;
}

ol li {
  font-size: 1.2rem;
  line-height: 40px;
}

.collapsible-body ol {
  padding-right: 90px;
}
#about {
  padding: 50px;
}
#supported {
  padding: 50px;
}

.timer {
  background-color: rgb(170, 75, 75);
  padding: 20px 25px;
  margin-top: 50px;
  font-size: 1.4rem;
}

