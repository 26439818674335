.input-field > label {
  right: 0 !important;
  left: auto !important;
}

.dropdown-content li {
  text-align: right !important;
  margin-right: 50px;
}

.select-wrapper input.select-dropdown {
  direction: rtl;
  margin-right: 100px;
}
